import { createSlice } from "@reduxjs/toolkit";
import { tabID } from "../../constants";

const tabLabels = [tabID.A_TAB, tabID.B_TAB, tabID.C_TAB];
const initialState = {
  isMaintenanceMode: false,
  initialAppLoading: true,
  isUserLoggedIn: false,
  isProfileDataLoading: false,
  profileData: {},
  activeTab: {
    id: 0,
    label: tabLabels[0],
  },
  cartItems: [],
  cartActions: {},
  openCartModal: false,
  openAuthModal: false,
  isShowRegister: false,
  showCartAfterLogin: false,
  isShowPaymentMode: false,
  paymentAmout: 0,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    disableInitialLoading: (state) => {
      state.initialAppLoading = false;
    },
    updateCartItems: (state, { payload }) => {
      const { action, id, name, count } = payload;
      if (action === "ADD") {
        state.cartItems.push(id);
        state.cartActions["action"] = action;
        state.cartActions[
          "message"
        ] = `"${name}" with ${count} occurance added!`;
        state.cartActions["title"] = "ADDED";
      } else if (action === "REMOVE") {
        const index = state.cartItems.indexOf(id);
        state.cartItems.splice(index, 1);
        state.cartActions["action"] = action;
        state.cartActions[
          "message"
        ] = `"${name}" with ${count} occurance removed!`;
        state.cartActions["title"] = "REMOVED";
      } else {
        state.cartItems = [];
      }
    },
    clearCartActions: (state) => {
      state.cartActions = {};
    },
    setUserLoggedIn: (state, { payload }) => {
      state.isUserLoggedIn = payload;
    },
    setProfileData: (state, { payload }) => {
      const { loading, data } = payload;
      state.isProfileDataLoading = loading;
      state.profileData = data;
    },
    showAuthDialog: (state, { payload }) => {
      const { openAuth, cartAfterLogin } = payload;
      state.openAuthModal = openAuth;
      state.showCartAfterLogin = cartAfterLogin;
    },
    showCartDialog: (state, { payload }) => {
      state.openCartModal = payload;
    },
    showRegisterForm: (state, { payload }) => {
      state.isShowRegister = payload;
    },
    showPaymentDialog: (state, { payload }) => {
      state.isShowPaymentMode = payload;
    },
    setPaymentAmount: (state, { payload }) => {
      state.paymentAmout = payload;
    },
    setActiveTab: (state, { payload }) => {
      state.activeTab = {
        id: payload,
        label: tabLabels[payload],
      };
    },
    resetAppSlice: () => {
      return initialState;
    },
  },
});

export const {
  resetAppSlice,
  disableInitialLoading,
  setProfileData,
  updateCartItems,
  clearCartActions,
  showCartDialog,
  showAuthDialog,
  showRegisterForm,
  setUserLoggedIn,
  showPaymentDialog,
  setPaymentAmount,
  setActiveTab,
} = appSlice.actions;
export default appSlice.reducer;
