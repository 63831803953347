const config = {
  // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
  basename: "",
  defaultPath: "/",
  defaultPathID: "",
  fontFamily: `"Lato", sans-serif`, //"Baloo Thambi 2", sans-serif
  fontSize: 16,
  backgroundColor: "#ffffff",
  borderColor: "#dddddd",
  borderRadius: 6,
  borderRadiusSmall: 4,
  borderRadiusLg: 10,
  gridSpacing: 3,
  gridSpacingSm: 2,
};

export default config;
