export const plotColors = {
  red: "#ed4319",
  orange: "#f4a347",
  green: "#a3c388",
  blue: "#1a99aa",
  darkBlue: "#103c46",
  greenAlt: "#88ba01",
};

export const mainCardTypes = {
  COMPONENT_WRAPPER_CARD: "COMPONENT_WRAPPER_CARD",
  COMPONENT_WRAPPER: "COMPONENT_WRAPPER",
};

export const tabID = {
  A_TAB: "Analyse",
  B_TAB: "Buy",
  C_TAB: "Check",
};
