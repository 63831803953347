import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";

const CraftSection = () => {
  const navigate = useNavigate();

  const handleRedirectToInfo = (e) => {
    e.preventDefault();
    navigate("/info");
  };
  return (
    <Box
      className="footer"
      sx={{ display: "none", padding: { xs: "2rem", md: "3rm" } }}
    >
      <Container maxWidth="lg">
        <Box textAlign="center" sx={{ margin: "0 auto" }}>
          <Typography variant="body2">
            Crafted with <img src="/static/images/icons/heart.svg" alt="Love" />{" "}
            by <strong>A for AI</strong> team.{" "}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default CraftSection;
